<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark height="64" color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Filter Jobs</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text large @click="getJobsWithFilter">Search</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-layout row wrap px-2 pt-4>
        <v-flex xs12 px-4>
          <v-text-field
            outlined
            hide-details
            mb-3
            label="Title or Description"
            v-model="jobTitleFilter"
          ></v-text-field>
          <v-checkbox
            hide-details
            label="Anonymous Client"
            v-model="isAnonymous"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'visible'
  ],
  data () {
    return {
      isAnonymous: '',
      jobTitleFilter: ''
    }
  },
  methods: {
    getJobsWithFilter () {
      let load = {
        jobTitleFilter: this.jobTitleFilter,
        isAnonymous: this.isAnonymous
      }
      this.$store.dispatch('jobs/setFilter', load || '')
        .then(res => {
          this.$emit('close')
        })
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
