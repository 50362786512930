<template>
  <v-container grid-list-md :px-5="$vuetify.breakpoint.mdAndUp" :py-0="!$vuetify.breakpoint.smAndDown" :pa-0="$vuetify.breakpoint.smAndDown" :my-5="$vuetify.breakpoint.mdAndUp">
    <v-layout row wrap justify-center>
      <v-flex xs12 md8 lg7 xl5 :py-0="$vuetify.breakpoint.smAndDown">
        <v-card :tile="$vuetify.breakpoint.mdAndDown" class="mb-1">
          <v-card-actions class="pa-3 px-3 primary">
            <span v-if="!$vuetify.breakpoint.mdAndDown" class="px-2 headline white--text">Job Feed</span>
            <span v-else class="px-2 title white--text">Job Feed</span>
            <v-spacer />
            <v-btn v-if="$vuetify.breakpoint.smAndDown" icon @click="mFilterIsVisible = true">
              <v-icon class="white--text">fas fa-filter</v-icon>
            </v-btn>
          </v-card-actions>
          </v-card>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            v-scroll="onScroll"
            v-show="fab"
            color="primary"
            @click="scrollToTop()"
            absolute
            dark
            fab
            bottom
            small
            right
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          <div v-if="!isFetching">
            <v-card v-for="job in jobs" :key="job.index" class="mb-1" :tile="$vuetify.breakpoint.mdAndDown">
              <v-container :px-4="$vuetify.breakpoint.mdAndUp" py-3 class="job-feed-hover" @click="atClicked(job._id)">
                <v-layout row wrap>
                  <v-flex xs12>
                    <p class="mb-0 body-2 font-weight-medium">{{ job.title }}</p>
                  </v-flex>
                  <v-flex xs12 class="caption grey--text mt-0">Posted {{ job.createdAt | momentFromNow }}</v-flex>
                  <v-flex xs12>
                    <v-card-actions class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
                      <span class="blue--text caption pr-3">Legal Advice</span>
                      <!-- <span class="grey--text caption pr-1">Time Frame</span><span class="caption black--text"> {{ job.daysToFinish }} {{ job.daysToFinish > 1 ? 'days' : 'day' }}</span> -->
                      <v-spacer></v-spacer>
                      <span v-if="job.bids > 0" class="caption black--text"><span class="grey--text">Proposals</span> {{ job.bids }}</span>
                      <span v-else class="caption grey--text">No proposals yet</span>
                    </v-card-actions>
                    <v-row v-else align="center" no-gutters>
                      <v-col cols="4">
                        <span class="blue--text caption">Legal Advice</span>
                      </v-col>
                      <v-col cols="4" class="text-center">
                        <!-- <span class="grey--text caption">Time Frame</span>
                        <span class="caption font-weight-medium"> {{ job.daysToFinish }} {{ job.daysToFinish > 1 ? 'days' : 'day' }}</span> -->
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <span v-if="job.bids > 0" class="caption"><span class="grey--text">Proposals</span><span class="font-weight-medium"> {{ job.bids }}</span></span>
                        <span v-else class="grey--text caption">No proposals</span>
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
            <v-card v-if="jobs.length > 0 && $vuetify.breakpoint.mdAndUp" class="mb-1">
              <v-card-actions>
                <v-layout v-if="!isMaxPage" row justify-center py-2>
                  <v-btn depressed large color="primary" @click="getMoreJobs()" :loading="isFetchingMoreJobs">Load More</v-btn>
                </v-layout>
                <v-layout v-if="isMaxPage && $vuetify.breakpoint.mdAndUp" justify-center align-center py-2 class="text-center">
                  <span class="px-2">All caught up!</span>
                  <v-btn class="primary" @click="scrollToTop()" dark small depressed>Refresh Job Feed</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="jobs.length < 0 && $vuetify.breakpoint.mdAndUp" class="pa-1 mt-1">
              <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                  <span>No tasks available yet.</span>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-else-if="jobs.length < 0 && $vuetify.breakpoint.smAndDown">
              <v-card-actions>
                <v-layout v-if="!isMaxPage" row justify-center py-2>
                  <v-btn depressed large color="primary" @click="getMoreJobs()" :loading="isFetchingMoreJobs">Load More</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </div>
          <div v-else>
            <v-skeleton-loader v-for="i in 4" :key="i" type="article"  class="mb-1"></v-skeleton-loader>
          </div>
      </v-flex>
      <v-flex v-if="!$vuetify.breakpoint.smAndDown" xs12 sm3 xl2>
        <v-card class="pa-3">
          <v-text-field
            v-model="jobTitleFilter"
            @click:clear="$store.dispatch('jobs/setFilter', '')"
            label="Search Task"
            dense
            clearable
            small
            outlined
            hide-details
            mb-3
          ></v-text-field>
          <v-btn
            depressed
            block
            class="mt-4"
            color="primary"
            @click="getJobsWithFilter"
          ><v-icon>search</v-icon> Search</v-btn>
        </v-card>
        <!-- <v-flex xs12 mt-2 pa-0>
          <v-sheet class="pa-3 dropShadow">
            <p class="text-xs-center primary--text body-2">You have {{ this.$store.getters['user/user'].user.credits }} available credits</p>
            <v-btn depressed outline block color="primary" to="/topup">Buy Credits</v-btn>
          </v-sheet>
        </v-flex> -->
      </v-flex>
    </v-layout>
    <MobileFilter :visible="mFilterIsVisible" @close="mFilterIsVisible = false"/>
  </v-container>
</template>

<script>
import io from 'socket.io-client'
import momentFromNow from '@/mixins/momentFromNowMixin'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import MobileFilter from '@/components/FindJobComponents/MobileFilter'

export default {
  name: 'JobFeed',
  components: {
    MobileFilter
  },
  mixins: [
    momentFromNow,
    toLocaleString
  ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      mFilterIsVisible: false,
      fab: false,
      isAnonymous: '',
      jobTitleFilter: ''
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
      this.getJobs()
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    atClicked (jobId) {
      this.$store.dispatch('proposals/removeSingleProposalAtJob')
      this.$router.push({ name: 'OpenJob', params: { problemId: jobId } })
      this.$store.commit('jobs/SET_OLD')
      this.$store.commit('jobs/CHANGE_VIEWEDJOB')
    },
    getJobsWithFilter () {
      let load = {
        jobTitleFilter: this.jobTitleFilter,
        isAnonymous: this.isAnonymous
      }
      this.$store.dispatch('jobs/setFilter', load || '')
    },
    getJobs () {
      if (!this.viewedJob) {
        this.$store.dispatch('jobs/getJobs')
      }
    },
    getMoreJobs () {
      this.$store.dispatch('jobs/getMoreJobs')
    },
    socketSaver () {
      this.socket.emit('joinUser', {
        user: this.$store.getters['user/user'].user._id
      })
    },
  },
  filters: {
    truncate: function (value) {
      if (value.length > 160) {
        return value.slice(0, 160) + '...'
      } else {
        return value
      }
    },
    averageAmount: function (value) {
      let resultArray = []
      let average = arr => arr.reduce((a, v) => a + v, 0) / arr.length

      for (let key in value) {
        if (!value[key].amount <= 0) {
          resultArray.push(value[key].amount)
        }
      }

      return average(resultArray)
    }
  },
  created () {
    this.getJobs()
    this.socketSaver()
  },
  computed: {
    jobs () {
      return this.$store.getters['jobs/jobs']
    },
    viewedJob () {
      return this.$store.getters['jobs/viewedJob']
    },
    isMaxPage () {
      return this.$store.getters['jobs/isMaxPage']
    },
    isFetching () {
      return this.$store.getters['jobs/isFetching']
    },
    isFetchingMoreJobs () {
      return this.$store.getters['jobs/isFetchingMoreJobs']
    }
  },
  mounted () {
    this.socket.on('post-task', data => {
      this.$store.dispatch('jobs/setRealTimeJobs', data)
    })
  },
  beforeRouteLeave (to, from, next) {
    this.socket.emit('leaveUser', {
      user: this.$store.getters['user/user'].user._id
    })
    next()
  }
}
</script>

<style scoped>
  .fade {
    position: relative;
    height: 4.4em; /* exactly three lines */
  }
  .fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
  .module {
    width: 100%;
    margin: 0 0 1em 0;
    overflow: hidden;
  }
  .job-feed-hover:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }
</style>
